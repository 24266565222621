import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ResponseChatBox,
  TextChevronBtn,
} from "@woojin0808/seniorz-react-ts-web-design-system";

import useJobSearchInfoStore from "stores/jobSearchInfo";

const LocationChat = () => {
  const navigate = useNavigate();

  const { locationList } = useJobSearchInfoStore();

  const onClickBtn = () => {
    navigate("/address");
  };

  return !locationList.length ? (
    <TextChevronBtn onClick={onClickBtn} width={15.8}>
      {"지역 선택하기"}
    </TextChevronBtn>
  ) : (
    <ResponseChatBox onClickModify={onClickBtn}>
      {locationList
        .map(({ city, district }) => `${city} ${district}`)
        .join(", ")}
    </ResponseChatBox>
  );
};

export default LocationChat;
