import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import {
  TextChevronBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";

import { getRegionCity, regionKeys } from "apis/region";

import styled from "styled-components";

const AddressCityList = () => {
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: regionKeys.getCity,
    queryFn: async () => await getRegionCity(),
    select: (response) => response.data.result,
  });

  const onClickHandler = (city: string) => {
    navigate(`${city}`);
  };

  return (
    <Container>
      <p>{"도/광역시를 선택하세요"}</p>
      <List>
        {data?.map((city, idx) => (
          <li key={idx}>
            <TextChevronBtn
              types={"secondary"}
              onClick={() => onClickHandler(city)}
            >
              {city}
            </TextChevronBtn>
          </li>
        ))}
      </List>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    padding: 2.2rem 1.6rem 1.5rem;
    ${typography.heading2};
    color: var(--color-black);
  }
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  flex: 1;
  gap: 1.2rem;
  padding: 1.5rem 1.6rem 10rem;
  overflow-y: auto;

  li {
    width: calc(50% - 0.6rem);
  }
`;

export default AddressCityList;
