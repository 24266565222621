import request, { BaseResponse } from "apis/core";
import { LoginUserInfo } from "userInfoTypes";

export const getLoginRequestDev = (
  socialLoginType: "kakao",
  redirectUrl: string
) => {
  const url = `/app/users/auth/${socialLoginType}/requestDev?callbackUrl=${redirectUrl}`;
  return request.get<BaseResponse<string>>(url);
};

export const getLoginDev = (
  socialLoginType: "kakao",
  code: string,
  callbackUrl: string
) => {
  const url = `/app/users/auth/${socialLoginType}/loginDev?code=${code}&callbackUrl=${callbackUrl}`;
  return request.get<
    BaseResponse<{
      user: LoginUserInfo;
      jwtToken: string;
    }>
  >(url);
};
