import React, { useEffect } from "react";
import { KakaoBtn } from "@woojin0808/seniorz-react-ts-web-design-system";
import LoginPage from "assets/2.0/login_page.png";
import styled from "styled-components";

const Login = () => {
  useEffect(() => {
    if (!window?.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
    }
  }, []);

  const onClickHandler = () => {
    if (window?.Kakao?.Auth) {
      window.Kakao.Auth.authorize({
        redirectUri: `${process.env.REACT_APP_BASE_URL}/login/callback/kakao`,
      });
    }
  };
  return (
    <Container>
      <img src={LoginPage} alt="login" />

      <KakaoBtn onClick={onClickHandler} />
    </Container>
  );
};

const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
  height: 100%;
  padding: 5.15rem 1.6rem 4rem;

  img {
    width: 100%;
  }
`;

export default Login;
