import styled from "styled-components";

export const DimmedLoadingLayout = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.8);
  z-index: 100;
`;
