import { useInfiniteQuery } from "@tanstack/react-query";
import {
  Filter,
  JobCard,
  OtherBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { getJobListCustom, jobKeys } from "apis/job";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import ResultList from "./result/ResultList";
import useJobSearchInfoStore from "stores/jobSearchInfo";

const FILTER_LIST = [
  {
    id: 0,
    text: "준비중...",
    onClick: () => "준비 중입니다.",
  },
  {
    id: 1,
    text: "준비중...",
    onClick: () => "준비 중입니다.",
  },
];

const MyResult = () => {
  const navigate = useNavigate();

  const { setClearAll } = useJobSearchInfoStore((state) => state.action);

  const locationList = localStorage.getItem("location") ?? "";
  const keywordIdList = localStorage.getItem("keyword") ?? "";
  const names = localStorage.getItem("names") ?? "";

  const {
    data: customData,
    fetchNextPage: customFetchNextPage,
    hasNextPage: customHasNextPage,
    isFetchingNextPage: customIsFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: jobKeys.getJobListCustom(
      !!locationList ? JSON.parse(locationList) : "",
      !!names
        ? JSON.parse(names)
        : !!keywordIdList
        ? JSON.parse(keywordIdList)
        : ""
    ),
    queryFn: async ({ pageParam }) =>
      await getJobListCustom({ page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.data.result.nextUrl
        ? Number(lastPage.data.result.nextUrl.split("page=")[1])
        : undefined;
    },
    select: (response) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return response.pages.reduce((acc: any[], val) => {
        const concatArr = val.data.result.jobInfoList
          ? acc.concat(val.data.result.jobInfoList)
          : acc;
        return concatArr;
      }, []);
    },
  });

  const customObserverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      !customObserverRef.current ||
      !customHasNextPage ||
      customIsFetchingNextPage
    )
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        customFetchNextPage();
      }
    });

    observer.observe(customObserverRef.current);

    return () => observer.disconnect();
  }, [customHasNextPage, customIsFetchingNextPage, customFetchNextPage]);

  //   const onClickNavigate = (id: number) => {
  //     navigate(`${id}`);
  //   };

  const onClickChange = () => {
    setClearAll();
    navigate("/chat");
  };
  const onClickAlert = () => console.log("aa");

  return (
    <Container>
      <p>AI 일자리 찾기</p>
      <ButtonSection>
        <section>
          <OtherBtn types={"primary"} onClick={onClickChange} />
          <OtherBtn types={"secondary"} onClick={onClickAlert} />
        </section>
        <FilterSection>
          <p>
            {`총 `}
            <b>{`${customData?.length}`}</b>
            {`개의 일자리를 찾았어요`}
          </p>
          <Filter btnList={FILTER_LIST} align="right" alignValue={0.1}>
            준비중
          </Filter>
        </FilterSection>
      </ButtonSection>
      <List>
        {customData?.map(({ id, company, location, name, type }, idx) => (
          <li key={id}>
            <button>
              <JobCard
                title={name}
                infoList={[
                  { id: 0, key: "기업", value: company },
                  { id: 1, key: "위치", value: location },
                  { id: 2, key: "고용형태", value: type },
                  { id: 3, key: "급여", value: "-" },
                ]}
              />
            </button>
          </li>
        ))}
        <div ref={customObserverRef} />
        {!customHasNextPage && <ResultList />}
      </List>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > p {
    padding: 2.2rem 1.6rem 1.5rem;
    ${typography.heading2};
    color: var(--color-black);
  }
`;

const ButtonSection = styled.div`
  padding: 2rem 1.6rem 1rem;

  section {
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;
  }
`;

const FilterSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    ${typography.body2};
    color: var(--color-gray-800);
    b {
      ${typography.heading3}
    }
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  padding: 1.5rem 1.6rem 10rem;
  overflow-y: auto;
  background-color: var(--color-gray-50);

  li {
    width: 100%;

    button {
      width: 100%;
      background-color: transparent;
      border: none;
    }
  }
`;

export default MyResult;
