import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { JWT_KEY } from "data/constant";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseResponse<T = any> = {
  isSuccess: boolean;
  code: number;
  message: string;
  result: T;
};

interface CustomInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<BaseResponse>>;
  };
  // getUri(config?: AxiosRequestConfig): string;
  // request<T>(config: AxiosRequestConfig): Promise<T>;
  // get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  // delete<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  // head<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  // options<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  // post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  // put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  // patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

export const request: CustomInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    accept: "application/json",
  },
});

export const chatRequest: CustomInstance = axios.create({
  baseURL: process.env.REACT_APP_CHAT_API,
  headers: {
    accept: "application/json",
  },
});

chatRequest.interceptors.request.use(
  (config) => {
    if (config.headers) {
      const token = localStorage.getItem(JWT_KEY);
      if (!token) return config;
      if (config.headers.Authorization === `Bearer ${token}`) return config;

      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.request.use(
  (config) => {
    if (config.url === "https://stdpay.inicis.com/stdjs/INIStdPay.js")
      config.baseURL = "";

    if (config.headers) {
      const token = localStorage.getItem(JWT_KEY);
      if (!token) return config;
      if (config.headers.Authorization === `Bearer ${token}`) return config;

      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    if (response.data.isSuccess) return response;
    else return Promise.reject(response.data.message);
  },
  (error) => {
    return Promise.reject(
      error.code === "ERR_NETWORK"
        ? "허용되지 않은 네트워크 접근입니다."
        : error
    );
  }
);

export default request;
