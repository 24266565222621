import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { getLoginDev } from "apis/users/user_auth_dev";
import { loginWithSocialProvider } from "apis/users/users";
import useAuth from "hooks/useAuth";

import type { AxiosResponse } from "axios";
import type { LoginUserInfo } from "userInfoTypes";
import type { BaseResponse } from "apis/core";
import useSignupStore from "stores/signup";
import Flower from "assets/lottie/loading.json";
import Lottie from "react-lottie";
import { SolidLoadingLayout } from "components/Common/Loading/SolidLoading/style";

const DELAY_DURATION = 2000;

const KakaoCallbackPage = () => {
  const code = new URL(window.location.href).searchParams.get("code");

  const { updateToken } = useSignupStore();

  const { mutateAsync: serviceMutate } = useMutation({
    mutationKey: ["login", "auth", "service"],
    mutationFn: async (loginCode: string) =>
      await loginWithSocialProvider("kakao", loginCode),
    onSuccess: async (response) => onSuccessHandler(response),
    onError: async (error) => onErrorHandler(error),
  });

  const { mutateAsync: localMutate } = useMutation({
    mutationKey: ["login", "auth", "local"],
    mutationFn: async (loginCode: string) =>
      await getLoginDev(
        "kakao",
        loginCode,
        `${process.env.REACT_APP_BASE_URL}/login/callback/kakao`
      ),
    onSuccess: async (response) => onSuccessHandler(response),
    onError: async (error) => onErrorHandler(error),
  });

  const onSuccessHandler = async (
    response: AxiosResponse<
      BaseResponse<{
        user: LoginUserInfo;
        jwtToken: string;
      }>
    >
  ) => {
    const { jwtToken, user } = response.data.result;

    const navigateFunc = () => {
      updateAuthToken(jwtToken);
      localStorage.setItem("uid", user.id.toString());
      navigate("/chat", { replace: true });
    };

    const timer = setTimeout(() => {
      navigateFunc();
    }, DELAY_DURATION);

    return () => clearTimeout(timer);
  };

  const onErrorHandler = async (error: Error) => {
    console.error(error);
    navigate("/login", { replace: true });
  };

  const navigate = useNavigate();

  const { updateAuthToken } = useAuth();

  useEffect(() => {
    if (code) {
      process.env.REACT_APP_ENV === "local"
        ? localMutate(code)
        : serviceMutate(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <SolidLoadingLayout>
      <Lottie options={{ animationData: Flower }} width={170} height={170} />;
    </SolidLoadingLayout>
  );
};

export default KakaoCallbackPage;
