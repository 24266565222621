import { commonStoreForm, Set } from "utils/store";

type SolidLoading = {
  isOpenSolidLoading: boolean;
  solidText: string;
  isOpenDimmedLoading: boolean;
};

type Action = {
  onShowLoading: (solidText: SolidLoading["solidText"]) => void;
  onHideLoading: () => void;
  onShowDimmedLoading: () => void;
  onHideDimmedLoading: () => void;
};

const initialState: SolidLoading = {
  isOpenSolidLoading: false,
  solidText: "",
  isOpenDimmedLoading: false,
};

const action = (set: Set<SolidLoading, Action>): Action => ({
  onShowLoading: (solidText: string) =>
    set((prev) => ({
      ...prev,
      solidText: solidText,
      isOpenSolidLoading: true,
    })),
  onHideLoading: () =>
    set(() => ({ solidText: "", isOpenSolidLoading: false })),
  onShowDimmedLoading: () => set(() => ({ isOpenDimmedLoading: true })),
  onHideDimmedLoading: () => set(() => ({ isOpenDimmedLoading: false })),
});

const useLoadingStore = commonStoreForm<SolidLoading, Action>({
  initialState,
  action,
});

export default useLoadingStore;
