import {
  DefaultPopupProps,
  DefaultPopupTypes,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { commonStoreForm, Set } from "utils/store";

type Action = {
  setPopup: (
    value: Omit<DefaultPopupProps<DefaultPopupTypes>, "isOpen">
  ) => void;
  setIsOpen: (isOpen: boolean) => void;
};

const initialState: DefaultPopupProps<DefaultPopupTypes> = {
  isOpen: false,
  types: "default",
  title: "",
  content: "",
  btnText: "",
  onClick: () => console.log("click"),
  btnTextSub: "",
  onClickSub: () => console.log("click sub"),
};

const action = (
  set: Set<DefaultPopupProps<DefaultPopupTypes>, Action>
): Action => ({
  setPopup: (value) => set((prev) => ({ ...prev, ...value })),
  setIsOpen: (isOpen) => set((prev) => ({ ...prev, isOpen })),
});

const usePopupStore = commonStoreForm<
  DefaultPopupProps<DefaultPopupTypes>,
  Action
>({
  initialState,
  action,
});

export default usePopupStore;
