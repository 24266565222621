import { useEffect } from "react";
import usePopupStore from "stores/popup";

export const useBackPressPopup = () => {
  const { setIsOpen } = usePopupStore((state) => state.action);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);

    const handleBackPress = () => {
      setIsOpen(true);

      window.history.pushState(null, "", window.location.href);
    };

    window.addEventListener("popstate", handleBackPress);

    return () => {
      window.removeEventListener("popstate", handleBackPress);
    };
  }, [setIsOpen]);
};
