import { create } from "zustand";
import { formatDate } from "utils/utility";

import type { Category } from "categoryTypes";
import type { AddressRequest } from "userAddressTypes";
import type { Agreements } from "userAgreementType";
import type { Gender } from "userInfoTypes";
import type { SignupRequest } from "userSignupTypes";
import type { UploadImageState } from "commonTypes";

type InitType = {
  token: string;
  gender: Gender | null;
  images: UploadImageState | null;
  address: AddressRequest;
} & Omit<SignupRequest, "gender" | "imageUrl" | "city" | "district">;
// Add token, gender for null, images for local path

const initSignup: InitType = {
  token: "",
  name: "",
  nickName: "",
  gender: null,
  images: null,
  birthday: "",
  category: [],
  address: { city: "", district: "" },
  agreementTypes: [],
};

type Action = {
  updateToken: (token: string) => void;
  updateName: (name: string) => void;
  updateNickname: (nickName: string) => void;
  updateGender: (gender: Gender) => void;
  updateImages: (images: UploadImageState) => void;
  updateBirthday: (birthday: string) => void;
  updateCategory: (category: Category[]) => void;
  updateAddress: (address: AddressRequest) => void;
  updateAgreementTypes: (agreementTypes: Agreements[]) => void;
  onDelete: (key: keyof InitType) => void;
};

const useSignupStore = create<InitType & Action>((set) => ({
  ...initSignup,
  updateToken: (token) => set((prev) => ({ ...prev, token })),
  updateName: (name) => set((prev) => ({ ...prev, name })),
  updateNickname: (nickName) => set((prev) => ({ ...prev, nickName })),
  updateGender: (gender) => set((prev) => ({ ...prev, gender })),
  updateImages: (images) => set((prev) => ({ ...prev, images })),
  updateBirthday: (birthday) =>
    set((prev) => ({ ...prev, birthday: formatDate(birthday) })),
  updateCategory: (category) => set((prev) => ({ ...prev, category })),
  updateAddress: (address) => set((prev) => ({ ...prev, address })),
  updateAgreementTypes: (agreementTypes) =>
    set((prev) => ({ ...prev, agreementTypes })),
  // delete is a common function
  onDelete: (key) => set((prev) => ({ ...prev, [key]: initSignup[key] })),
}));

export default useSignupStore;
