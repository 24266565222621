import request, { BaseResponse } from "apis/core";
import type { LoginUserInfo, MyInfo, UserInfo } from "userInfoTypes";
import type { Category } from "categoryTypes";
import { userAddressKeys } from "./users_address";
import { userAuthKeys } from "./user_auth";
import { AxiosRequestConfig } from "axios";
import { UploadImageInfo } from "commonTypes";

export const getSocialLogoutUrl = (socialLoginType: "kakao") => {
  const url = `/app/users/auth/${socialLoginType}/logout`;
  return request.get<BaseResponse<string>>(url);
};

export const loginWithSocialProvider = (
  socialLoginType: "kakao",
  code: string
) => {
  const url = `/app/users/auth/${socialLoginType}/login?code=${code}`;
  return request.get<
    BaseResponse<{
      user: LoginUserInfo;
      jwtToken: string;
    }>
  >(url);
};

export const getMyUserInfo = () => {
  const url = `/app/users/me`;
  return request.patch<BaseResponse<MyInfo>>(url);
};

interface PraiseCategory {
  code: Category;
  displayName: string;
  count: number;
}

interface PraiseList {
  id: number;
  praiseType: string;
  reason: string;
  reporterUserId: number;
  reporterUserNickName: string;
  createDate: Date;
}

export interface PraiseProps {
  praiseCountList: PraiseCategory[];
  praiseList: PraiseList[];
}

export const getMyPraise = () => {
  const uid = localStorage.getItem("uid");
  const url = `/app/users/${uid}/praise`;
  return request.get<BaseResponse<PraiseProps>>(url);
};

export const withdrawUser = (reason: string) => {
  const url = "/app/users/withdrawal";
  return request.delete(url, { data: { reason } });
};

export const updateUser = async (data: {
  nickName: string;
  imageUrl: string;
  category: Category[];
  memo: string;
}) => {
  const url = "/app/users";
  return await request.patch(url, data);
};

export const postProfileImage = async (
  imageInfo: UploadImageInfo,
  config?: AxiosRequestConfig
) => {
  const url = "/app/users/profile-image";

  const formData = new FormData();

  const base64ImageResponse = await fetch(imageInfo.base64);
  const imageBlob = await base64ImageResponse.blob();
  const imageFile = new File([imageBlob], imageInfo.name);

  formData.append("file", imageFile);

  const headers = {
    headers: { "Content-Type": "multipart/form-data" },
    ...config,
  };

  return await request.post<BaseResponse<string>>(url, formData, headers);
};

export const getImageUrl = async (data: FormData) => {
  return await request({
    method: "POST",
    url: `/app/users/profile-image`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getUserInfo = (userId: number) => {
  const url = `/app/users/${userId}`;
  return request.get<BaseResponse<UserInfo>>(url);
};

export const userHeadKey = ["user"];

export const userKeys = {
  all: [...userHeadKey] as const,
  loginUrl: (loginType: "kakao") =>
    [...userHeadKey, "login", loginType, "url"] as const,
  logoutUrl: (logoutType: "kakao") =>
    [...userHeadKey, "logout", logoutType] as const,
  me: [...userHeadKey, "me"] as const,
  updateUser: [...userHeadKey, "update"] as const,
  postProfileImage: [...userHeadKey, "profile-image"] as const,
  // ./users/users_address.ts
  ...userAuthKeys(userHeadKey),
  ...userAddressKeys(userHeadKey),
};
