import request, { BaseResponse } from "apis/core";
import type { AxiosRequestConfig } from "axios";
import type { AddressRequest } from "userAddressTypes";

export const getRegionCity = (config?: AxiosRequestConfig) => {
  const url = "/app/region/city";
  return request.get<BaseResponse<string[]>>(url, config);
};

export const getRegionDistrict = (
  city: string,
  config?: AxiosRequestConfig
) => {
  const url = `/app/region/${city}/district`;
  return request.get<BaseResponse<AddressRequest[]>>(url, config);
};

const headKey = ["region"];

export const regionKeys = {
  all: [...headKey] as const,
  getCity: [...headKey, "city"] as const,
  getDistrict: (district: string) =>
    [...headKey, "city", "district", district] as const,
};
