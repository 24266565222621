// import React from "react";

// const Home = React.lazy(() => import("./pages/home"));
// const Join = React.lazy(() => import("./pages/join"));
// const Login = React.lazy(() => import("./pages/login"));
// const Logout = React.lazy(() => import("./pages/logout"));
// const Guide = React.lazy(() => import("./pages/guide"));
// const Search = React.lazy(() => import("./pages/articles"));
// const Profiles = React.lazy(() => import("./pages/profiles"));
// const Report = React.lazy(() => import("./pages/report"));
// const Upload = React.lazy(() => import("./pages/upload"));
// const Letters = React.lazy(() => import("./pages/letters"));
// const Pay = React.lazy(() => import("./pages/pay"));
// const Schedules = React.lazy(() => import("./pages/schedules"));
// const Terms = React.lazy(() => import("./pages/terms"));
// const Mypage = React.lazy(() => import("./pages/mypage"));
// const NotFound = React.lazy(() => import("./pages/NotFound"));

// const routes = [
//   { path: "/", element: Home },
//   { path: "/join/*", element: Join },
//   { path: "/login/*", element: Login },
//   { path: "/logout/*", element: Logout },
//   { path: "/guide/*", element: Guide },
//   { path: "/articles/*", element: Search },
//   { path: "/profiles/:id", element: Profiles },
//   { path: "/report/*", element: Report },
//   { path: "/upload/*", element: Upload },
//   { path: "/letters/*", element: Letters },
//   { path: "/pay/*", element: Pay },
//   { path: "/schedules/*", element: Schedules },
//   { path: "/terms/*", element: Terms },
//   { path: "/mypage/*", element: Mypage },
//   { path: "*", element: NotFound },
// ];

// export default routes;

import Home from "pages/2.0/Home";
import Login from "pages/2.0/Login";
import KakaoCallbackPage from "pages/2.0/KakaoCallbackPage";
import Chat from "pages/2.0/Chat";
import AddressCityList from "pages/2.0/AddressCityList";
import AddressDistrictList from "pages/2.0/AddressDistrictList";
import AddressConfirm from "pages/2.0/AddressConfirm";
import ChatResult from "pages/2.0/ChatResult";
import MyResult from "pages/2.0/MyResult";
import MyResultDetail from "pages/2.0/MyResultDetail";

const routes = [
  { path: "/", element: Home },
  { path: "/login", element: Login },
  { path: "/login/callback/*", element: KakaoCallbackPage },
  { path: "/chat", element: Chat },
  { path: "/address", element: AddressCityList },
  { path: "/address/:city", element: AddressDistrictList },
  { path: "/address/confirm", element: AddressConfirm },
  { path: "/chat/result", element: ChatResult },
  { path: "/my/result", element: MyResult },
  { path: "/my/result/:id", element: MyResultDetail },
];

export default routes;
