import React from "react";

import Lottie from "react-lottie";
import Loading from "assets/2.0/loading_lottie.json";

import { SolidLoadingLayout } from "./style";

const SolidLoading = ({ text }: { text: string }) => {
  return (
    <SolidLoadingLayout>
      <p>{text}</p>
      <Lottie options={{ animationData: Loading }} width={170} height={170} />
    </SolidLoadingLayout>
  );
};

export default SolidLoading;
