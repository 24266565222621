import { typography } from "@woojin0808/seniorz-react-ts-web-design-system";
import styled from "styled-components";

export const SolidLoadingLayout = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;

  img {
    width: 15rem;
    aspect-ratio: 1/1;
  }

  p {
    ${typography.heading2};
    color: var(--color-grey-900);
  }
`;
