import { JWT_KEY } from "data/constant";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { authTokenAtom } from "recoil/login";

const useAuth = () => {
  const [authToken, setAuthToken] = useRecoilState(authTokenAtom);
  const isLogined = authToken !== null;

  useEffect(() => {
    const tokenFromStorage = localStorage.getItem(JWT_KEY);
    setAuthToken(tokenFromStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAuthToken = useCallback((newToken: string) => {
    setAuthToken(newToken);
    localStorage.setItem(JWT_KEY, newToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAuthToken = useCallback(() => {
    setAuthToken(null);
    localStorage.removeItem(JWT_KEY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLogined, updateAuthToken, clearAuthToken };
};

export default useAuth;
