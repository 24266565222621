import React from "react";
import styled from "styled-components";

const MyResultDetail = () => {
  return <Container></Container>;
};

const Container = styled.div``;

export default MyResultDetail;
