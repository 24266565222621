import React, { useMemo } from "react";
import {
  DefaultCard,
  DoubleBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { useLocation, useNavigate } from "react-router-dom";
import useJobSearchInfoStore from "stores/jobSearchInfo";
import styled from "styled-components";

import type { AddressRequest } from "userAddressTypes";

const AddressConfirm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { setLocationList } = useJobSearchInfoStore((state) => state.action);

  const list: AddressRequest[] = useMemo(() => {
    const districtList: string[] = state.district.split(",");
    return districtList.map((index) => ({ city: state.city, district: index }));
  }, [state]);

  const onClickModify = () => {
    navigate(-2);
  };

  const onClickConfirm = () => {
    setLocationList(list);
    navigate(-3);
  };

  return (
    <Container>
      <p>{"이대로 선택할까요?\n최대 3곳까지 선택 가능합니다."}</p>
      <List>
        {list?.map(({ city, district }, idx) => (
          <li key={idx}>
            <DefaultCard>{`${city} ${district}`}</DefaultCard>
          </li>
        ))}
      </List>
      <ButtonSection>
        <DoubleBtn
          textShort={"수정"}
          textLong={"지역 입력 완료"}
          onClickShort={onClickModify}
          onClickLong={onClickConfirm}
        />
      </ButtonSection>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    padding: 2.2rem 1.6rem 1.5rem;
    ${typography.heading2};
    color: var(--color-black);
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  padding: 1.5rem 1.6rem 10rem;
  overflow-y: auto;

  li {
    width: 100%;
  }
`;

const ButtonSection = styled.section`
  width: 100%;
  padding: 0px 1.6rem 4rem;
`;

export default AddressConfirm;
