import React from "react";

import Lottie from "react-lottie";
import Flower from "assets/lottie/loading_transparent.json";

import { DimmedLoadingLayout } from "./style";

const DimmedLoading = () => {
  return (
    <DimmedLoadingLayout>
      <Lottie options={{ animationData: Flower }} width={120} height={120} />
    </DimmedLoadingLayout>
  );
};

export default DimmedLoading;
