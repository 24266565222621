export const JWT_KEY = "jwt";

export const IMAGE_UPLOAD_LIMIT_NUM = 3;

export const RECENT_ADDRESS_KEY = "recent_address";

export const SAVE_RECENT_ADDRESS_NUMBER = 3;

export const SIGNUP_CONSTANT: {
  title: { [key in string]: string };
  toast: object;
  warn: object;
} = {
  title: {
    "1": "시니어즈에 오신 걸 환영합니다!\n이름을 알려주세요.",
    "2": "시니어즈는 별명을 사용해요.\n어떻게 불러드릴까요?",
    "3": "성별을 선택해주세요.",
    "4": "나를 대표하는\n프로필 사진을 등록해주세요!",
    "5": "거의 다 왔어요!\n생년월일을 알려주세요.",
    "6": "이웃에게 어떤 도움을\n주실 수 있나요?",
    "7": "어떤 동네의 이웃분이신가요?\n주소를 알려주세요.",
    "8": "시니어즈 서비스 이용약관에\n동의해주세요.",
  },
  toast: {},
  warn: {},
};

export const LEVELS = [
  {
    label: "씨앗",
    value: "SEED",
    desc: "무궁무진한 가능성을 가진 씨앗을 드립니다.\n이웃과의 교류를 통해 예쁜 꽃으로 피워보세요.",
  },
  {
    label: "새싹",
    value: "SPROUT",
    desc: "이웃과의 따뜻한 소통으로 새싹이 피어났어요.\n더 많은 소통을 통해 새싹을 키워보세요!",
  },
  {
    label: "줄기",
    value: "STEM",
    desc: "이웃의 칭찬을 먹고 새싹이 무럭무럭 자랐네요!\n이제 곧 꽃이 필 것 같아요. 조금 더 힘을 내요!",
  },
  {
    label: "꽃봉오리",
    value: "BUD",
    desc: "예쁜 꽃봉오리가 생겼어요. 어떤 꽃이 필지 궁금하지 않나요?\n칭찬을 주고받으며 꽃을 피워보세요!",
  },
  {
    label: "꽃",
    value: "FLOWER",
    desc: "이웃에게 친절히 대해주셔서 감사해요.\n따뜻한 마음으로 해바라기가 멋지게 자랐습니다!",
  },
  {
    label: "만개화",
    value: "FULL_BLOOM",
    desc: "축하해요, 여기에서 가장 크고 예쁜 꽃을 피워내셨어요!\n이웃에게 많은 도움을 주셔서 감사합니다.",
  },
] as const;

export type FlowerLevel = (typeof LEVELS)[number]["value"];

export const TYPES = [
  { code: "KIND", displayName: "친절해요" },
  { code: "FAST", displayName: "손이 빨라요." },
  { code: "FUNNY", displayName: "재미있어요" },
  { code: "ACCURATE", displayName: "정확해요!" },
  { code: "PUNCTUAL", displayName: "시간약속을 잘 지켜요." },
] as const satisfies readonly { code: PraiseType; displayName: string }[];

export const PraiseType = {
  KIND: "KIND",
  FAST: "FAST",
  FUNNY: "FUNNY",
  ACCURATE: "ACCURATE",
  PUNCTUAL: "PUNCTUAL",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PraiseType = (typeof PraiseType)[keyof typeof PraiseType];
