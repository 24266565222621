import { useMutation } from "@tanstack/react-query";
import {
  DefaultCard,
  DoubleBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import {
  JobCareerRequest,
  JobLocationRequest,
  postJobCareer,
  postJobLocation,
} from "apis/job";
import React from "react";
import { useNavigate } from "react-router-dom";
import useJobSearchInfoStore, { KeywordSelectList } from "stores/jobSearchInfo";
import useLoadingStore from "stores/loading";
import styled from "styled-components";

const TITLE_LIST: Omit<
  { [key in keyof KeywordSelectList]: string },
  "computerDetail"
> = {
  location: "일하고 싶은 장소",
  physical: "몸을 많이 쓰는 업무 가능",
  preferSit: "앉아서 하는 일 선호",
  home: "집에서 근무 선호",
  computer: "컴퓨터 사용 / 사용 정도",
  write: "수작업 선호",
  communication: "다른 사람과 소통 선호",
  time: "일하는 시간 자유롭게 조절 선호",
  simple: "단순 업무 선호",
  rule: "정해진 규칙 있는 업무 선호",
};

const ChatResult = () => {
  const navigate = useNavigate();

  const locationList = useJobSearchInfoStore((state) => state.locationList);
  const keywordIdList = useJobSearchInfoStore((state) => state.keywordIdList);
  const names = useJobSearchInfoStore((state) => state.names);
  const { setKeywordIdList } = useJobSearchInfoStore((state) => state.action);

  const { onShowLoading, onHideLoading } = useLoadingStore(
    (state) => state.action
  );

  const { mutateAsync: locationMutate } = useMutation({
    mutationFn: async (body: JobLocationRequest) => await postJobLocation(body),
    onSuccess: async () => {
      if (names.length > 0) {
        await careerMutate({
          careerKeywordIdList: [],
          hasCareer: true,
          names: names,
        });
      } else {
        const filtered = Object.entries(keywordIdList)
          .map(([key, value]) => value?.value ?? null)
          .filter((index) => {
            if (index === "possible") return false;
            if (index === null) return false;
            return true;
          }) as [number[]];

        const careerKeywordIdList = filtered.reduce((a, v) => a.concat(v), []);

        await careerMutate({
          careerKeywordIdList,
          hasCareer: true,
          names: [],
        });
      }
    },
  });
  const { mutateAsync: careerMutate } = useMutation({
    mutationFn: async (body: JobCareerRequest) => await postJobCareer(body),
    onSuccess: async () => {
      onHideLoading();
      navigate("/my/result", { replace: true });
    },
  });

  if (names.length > 0) {
    const onClickModify = () => {
      navigate("/chat");
    };

    const onClickConfirm = async () => {
      localStorage.setItem("location", JSON.stringify(locationList));
      localStorage.setItem("names", JSON.stringify(names));
      localStorage.removeItem("keyword");
      onShowLoading("잠시만 기다려주세요\nAI가 딱 맞는 일자리를 찾고 있어요!");
      await locationMutate({ locationList: locationList });
    };
    return (
      <Container>
        <p>{"지금까지 입력한 정보를\n다시한번 확인해주세요"}</p>
        <List>
          <li>
            <DefaultCard hasTitle={true} title={"지역"}>
              {locationList
                .map((idx) => `${idx.city} ${idx.district}`)
                .join(", ")}
            </DefaultCard>
          </li>
          <li>
            <DefaultCard hasTitle={true} title={"일자리 입력"}>
              {names.join(", ")}
            </DefaultCard>
          </li>
        </List>
        <ButtonSection>
          <DoubleBtn
            textShort={"수정"}
            textLong={"해당 정보로 검색하기"}
            onClickShort={onClickModify}
            onClickLong={onClickConfirm}
          />
        </ButtonSection>
      </Container>
    );
  }

  const list = Object.keys(keywordIdList)
    .map((key) => {
      const itemKey = key as keyof KeywordSelectList;
      const item = keywordIdList[itemKey];

      // computer.value가 "possible"일 때, computerDetail의 값을 사용
      if (
        itemKey === "computer" &&
        item?.value === "possible" &&
        keywordIdList.computerDetail
      ) {
        return {
          title: TITLE_LIST[itemKey],
          text: keywordIdList.computerDetail.text,
        };
      }

      if (itemKey === "computerDetail") {
        return {
          title: "",
          text: null,
        };
      }
      // 일반적으로는 item의 값 그대로 사용
      return {
        title: TITLE_LIST[itemKey],
        text: item?.text,
      };
    })
    .filter((item) => item.text !== null);

  const onClickModify = () => {
    if (setKeywordIdList) {
      setKeywordIdList({ key: "rule", value: null });
    }
    navigate("/chat");
  };

  const onClickConfirm = async () => {
    localStorage.setItem("location", JSON.stringify(locationList));
    localStorage.setItem("keyword", JSON.stringify(keywordIdList));
    localStorage.removeItem("names");
    onShowLoading("잠시만 기다려주세요\nAI가 딱 맞는 일자리를 찾고 있어요!");
    await locationMutate({ locationList: locationList });
  };

  return (
    <Container>
      <p>{"지금까지 입력한 정보를\n다시한번 확인해주세요"}</p>
      <List>
        {[
          {
            title: "지역",
            text: locationList
              .map((idx) => `${idx.city} ${idx.district}`)
              .join(", "),
          },
          ...list,
        ]?.map(({ title, text }, idx) => (
          <li key={idx}>
            <DefaultCard hasTitle={true} title={title}>
              {text ?? ""}
            </DefaultCard>
          </li>
        ))}
      </List>
      <ButtonSection>
        <DoubleBtn
          textShort={"수정"}
          textLong={"해당 정보로 검색하기"}
          onClickShort={onClickModify}
          onClickLong={onClickConfirm}
        />
      </ButtonSection>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > p {
    padding: 2.2rem 1.6rem 1.5rem;
    ${typography.heading2};
    color: var(--color-black);
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  padding: 1.5rem 1.6rem 10rem;
  overflow-y: auto;

  li {
    width: 100%;
  }
`;

const ButtonSection = styled.section`
  width: 100%;
  padding: 0px 1.6rem 4rem;
`;

export default ChatResult;
