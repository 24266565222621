import {
  GuideChatBox,
  TextareaInput,
  TextSmallBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import useJobSearchInfoStore from "stores/jobSearchInfo";
import styled from "styled-components";

const KeywordInputChat = ({
  isNextWithInput,
  setDisabled,
}: {
  isNextWithInput: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setNames } = useJobSearchInfoStore((state) => state.action);

  const [firstInput, setFirstInput] = useState<string>("");
  const [secondInput, setSecondInput] = useState<string>("");
  const [thirdInput, setThirdInput] = useState<string>("");

  const [inputNum, setInputNum] = useState<number>(1);

  useEffect(() => {
    if (isNextWithInput) {
      if (!!thirdInput) return setNames([firstInput, secondInput, thirdInput]);
      if (!!secondInput) return setNames([firstInput, secondInput]);
      if (!!firstInput) return setNames([firstInput]);
    }
  }, [isNextWithInput, firstInput, secondInput, thirdInput, setNames]);

  const disabled = useMemo(() => {
    if (inputNum === 3) {
      return !firstInput || !secondInput || !thirdInput;
    }
    if (inputNum === 2) {
      return !firstInput || !secondInput;
    }
    if (inputNum === 1) {
      return !firstInput;
    }
    return true;
  }, [inputNum, firstInput, secondInput, thirdInput]);

  useEffect(() => {
    setDisabled(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <>
      <GuideChatBox title={"일자리를 직접 입력해주세요."}>
        {"각 칸에 하나씩 입력해주세요.\n( 예시 : 요양보호사)"}
      </GuideChatBox>
      <List>
        <li>
          <p>1</p>
          <TextareaInput
            id={"first"}
            value={firstInput}
            maxLine={1}
            placeholder="일자리를 입력해주세요"
            onChange={(e) => setFirstInput(e.target.value)}
          />
        </li>
        {inputNum >= 2 && (
          <li>
            <p>2</p>
            <TextareaInput
              id={"second"}
              value={secondInput}
              maxLine={1}
              placeholder="(선택) 일자리를 입력해주세요"
              onChange={(e) => setSecondInput(e.target.value)}
            />
          </li>
        )}
        {inputNum >= 3 && (
          <li>
            <p>3</p>
            <TextareaInput
              id={"third"}
              value={thirdInput}
              maxLine={1}
              placeholder="(선택) 일자리를 입력해주세요"
              onChange={(e) => setThirdInput(e.target.value)}
            />
          </li>
        )}
        {inputNum < 3 && (
          <TextSmallBtn
            onClick={() => setInputNum((prev) => (prev < 3 ? prev + 1 : prev))}
          >
            {"추가하기"}
          </TextSmallBtn>
        )}
      </List>
    </>
  );
};

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0rem;

  li {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    p {
      ${typography.heading3};
      color: var(--color-black);
    }
  }
`;

export default KeywordInputChat;
