import { JWT_KEY } from "../data/constant";

/**
 * @param {string} email 이메일 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidEmail = (email: string) => {
  return (
    email.match(
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,4}$/i
    ) !== null
  );
};

export const isValidPassword = (password: string) => {
  return password.match(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/);
};

/**
 * @param {string} phoneNumber 전화번호 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.match(/^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/);
};

/**
 * @param {string} input 전화번호 형식으로 정규식 변환
 * @returns {string|undefined}
 */
export const formatPhoneNumber = (input?: string) => {
  return input
    ?.replace(/[^0-9]/g, "")
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    .replace(/(-{1,2})$/g, "");
};

export const getJwt = () => window.localStorage.getItem(JWT_KEY) ?? "";

export const saveJwt = (jwt: string) => {
  window.localStorage.setItem(JWT_KEY, jwt);
};

export const clearJwt = () => {
  window.localStorage.removeItem(JWT_KEY ?? "");
};

/*export const saveUser = (user: GetUsersUserIdResponse) => {
  window.localStorage.setItem(
    process.env.REACT_APP_LOGIN_INFO ?? "",
    JSON.stringify(user) ?? ""
  );
};*/

export const clearUser = () => {
  window.localStorage.removeItem(process.env.REACT_APP_LOGIN_INFO ?? "");
};

export const getUserEmail = () => {
  const loginInfoValue = window.localStorage.getItem(
    process.env.REACT_APP_LOGIN_INFO ?? ""
  );
  if (loginInfoValue === null) {
    return "";
  } else {
    const loginInfo = JSON.parse(loginInfoValue);
    return loginInfo.email ?? "";
  }
};

export const getUserName = (): string => {
  const loginInfoValue = window.localStorage.getItem(
    process.env.REACT_APP_LOGIN_INFO ?? ""
  );
  if (loginInfoValue === null) {
    return "";
  } else {
    const loginInfo = JSON.parse(loginInfoValue);
    return loginInfo.name ?? "";
  }
};

/**
 * @param {string} value 생년월일(숫자만)
 * @returns {string} `YYYY. MM. DD`
 */
export const formatDate = (value: string) => {
  const str = value.replace(/[^0-9]/g, "");
  let tmp = "";

  if (str.length <= 4) {
    return str;
  } else if (str.length <= 6) {
    tmp += str.substring(0, 4);
    tmp += ". ";
    tmp += str.substring(4);
    return tmp;
  } else {
    tmp += str.substring(0, 4);
    tmp += ". ";
    tmp += str.substring(4, 6);
    tmp += ". ";
    tmp += str.substring(6);
    return tmp;
  }
};

/**
 *
 * @param value 생년월일(YYYY. MM. DD)
 * @returns 현재 시간 기준 만 나이
 */
export const getWesternAge = (value: string) => {
  const tmp = value.split(". ").join("");
  const birthday = new Date(
    +tmp.substring(0, 4),
    +tmp.substring(4, 6) - 1,
    +tmp.substring(6)
  );
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();

  const todayMonth = today.getMonth() + 1;
  const birthMonth = birthday.getMonth() + 1;

  if (
    birthMonth > todayMonth ||
    (birthMonth === todayMonth && birthday.getDate() >= today.getDate())
  ) {
    age--;
  }

  return age;
};

export const isToday = (dateTime: string) => {
  const [year, month, date] = dateTime.split(" ")[0].split("-").map(Number);
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDate = today.getDate();

  if (
    new Date(currentYear, currentMonth, currentDate).getTime() ===
    new Date(year, month - 1, date).getTime()
  )
    return true;
  else return false;
};

export const isTomorrow = (dateTime: string) => {
  const [year, month, date] = dateTime.split(" ")[0].split("-").map(Number);
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDate = today.getDate();

  if (
    new Date(year, month - 1, date).getTime() -
      new Date(currentYear, currentMonth, currentDate).getTime() ===
    86400000
  )
    return true;
  else return false;
};

export const renderDateTime = (dateTime: string) => {
  const [date] = dateTime.split(" ");
  const [, month, day] = date.split("-").map(Number);

  return `${month}월 ${day}일`;
};

export const isMyVillage = (
  address: string,
  { city, district }: { city: string; district: string }
) => {
  const [firstCity, firstDistrict] = address.split(" ");

  if (
    (firstCity === city && firstDistrict === district) ||
    (firstCity === "세종특별자치시" && firstCity === city)
  )
    return true;
  else return false;
};
