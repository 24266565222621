import DimmedLoading from "components/Common/Loading/DimmedLoading";
import SolidLoading from "components/Common/Loading/SolidLoading";
import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "routes";
import useLoadingStore from "stores/loading";
import usePopupStore from "stores/popup";
import styled from "styled-components";
import Popup from "./popup/Popup";

const App = () => {
  const isOpenSolidLoading = useLoadingStore(
    (state) => state.isOpenSolidLoading
  );
  const isOpenDimmedLoading = useLoadingStore(
    (state) => state.isOpenDimmedLoading
  );
  const isOpen = usePopupStore((state) => state.isOpen);
  const popupProps = usePopupStore((state) => state);
  const solidText = useLoadingStore((state) => state.solidText);

  return (
    <Routes>
      {routes.map(
        (route, idx) =>
          route.element && (
            <Route
              key={idx}
              path={route.path}
              element={
                <DefaultLayout>
                  <route.element />
                  {isOpen && <Popup {...popupProps} />}
                  {isOpenSolidLoading && <SolidLoading text={solidText} />}
                  {isOpenDimmedLoading && <DimmedLoading />}
                </DefaultLayout>
              }
            />
          )
      )}
    </Routes>
  );
};

const DefaultLayout = styled.main`
  position: relative;
  max-width: 41.2rem;
  height: 100vh;
  background-color: var(--color-white);
  margin: auto;
  overflow: hidden;
`;

export default App;
