import { useInfiniteQuery } from "@tanstack/react-query";
import {
  JobCard,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { getJobList, jobKeys } from "apis/job";
import React, { useEffect, useRef } from "react";
import { ReactComponent as Search } from "assets/2.0/search.svg";
import styled from "styled-components";

const ResultList = () => {
  const locationList = localStorage.getItem("location") ?? "";
  const keywordIdList = localStorage.getItem("keyword") ?? "";
  const names = localStorage.getItem("names") ?? "";

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: jobKeys.getJobList(
        !!locationList ? JSON.parse(locationList) : "",
        !!names
          ? JSON.parse(names)
          : !!keywordIdList
          ? JSON.parse(keywordIdList)
          : ""
      ),
      queryFn: async ({ pageParam }) => await getJobList({ page: pageParam }),
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        return lastPage.data.result.nextUrl
          ? Number(lastPage.data.result.nextUrl.split("page=")[1])
          : undefined;
      },
      select: (response) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return response.pages.reduce((acc: any[], val) => {
          const concatArr = val.data.result.jobInfoList
            ? acc.concat(val.data.result.jobInfoList)
            : acc;
          return concatArr;
        }, []);
      },
    });

  const observerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!observerRef.current || !hasNextPage || isFetchingNextPage) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchNextPage();
      }
    });

    observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <>
      <Divide>
        <Search width={46} height={46} />
        <p>{"이런 일자리는 어떠세요?"}</p>
        <span>{"선택하신 지역에서 구인 중인 일자리예요"}</span>
      </Divide>
      {data?.map(({ id, company, location, name, type }, idx) => (
        <li key={id}>
          <JobCard
            title={name}
            infoList={[
              { id: 0, key: "기업", value: company },
              { id: 1, key: "위치", value: location },
              { id: 2, key: "고용형태", value: type },
              { id: 3, key: "급여", value: "-" },
            ]}
          />
        </li>
      ))}
      <div ref={observerRef} />
    </>
  );
};

const Divide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  padding: 1.6rem;
  background-color: var(--color-yellow-1);
  color: var(--color-gray-900);

  p {
    ${typography.heading3};
  }

  span {
    ${typography.body2};
  }
`;

export default ResultList;
