import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";

import { ChatSelectBox } from "@woojin0808/seniorz-react-ts-web-design-system";

import useJobSearchInfoStore from "stores/jobSearchInfo";
import styled from "styled-components";
import KeywordSelectChat from "./KeywordSelectChat";
import KeywordInputChat from "./KeywordInputChat";

const RecommendBtnList = [
  { text: "예", value: true },
  { text: "아니오, 추천해주세요", value: false },
];

const KeywordChat = ({
  isNextWithInput,
  setDisabled,
}: {
  isNextWithInput: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
}) => {
  const locationList = useJobSearchInfoStore((state) => state.locationList);
  const isNotRecommended = useJobSearchInfoStore(
    (state) => state.isNotRecommended
  );
  const { setIsNotRecommended, setClearKeyword, setClearNames } =
    useJobSearchInfoStore((state) => state.action);

  const isSelectRecommendation = useMemo(() => {
    if (isNotRecommended === undefined) return null;
    return RecommendBtnList.filter(
      (index) => index.value === isNotRecommended
    )[0];
  }, [isNotRecommended]);

  const onSelectRecommendation = (
    a: { text: string; value: boolean } | null
  ) => {
    setIsNotRecommended(a ? a.value : undefined);
  };

  useEffect(() => {
    if (isNotRecommended === true) {
      setClearKeyword();
    } else if (isNotRecommended === false) {
      setClearNames();
    }
  }, [isNotRecommended, setClearKeyword, setClearNames]);

  return (
    <Container>
      {locationList.length > 0 && (
        <ChatSelectBox
          btnListWidth={23}
          selectValue={isSelectRecommendation}
          setSelectValue={onSelectRecommendation}
          title={"찾고 계신 구체적인 일자리가 있나요?"}
          content={
            "일자리를 직접 입력해서 검색할 수 있어요. ( 예시 : 요양보호사, 조리사, 경비 등)"
          }
          btnList={RecommendBtnList}
        />
      )}
      {isNotRecommended === false ? (
        <KeywordSelectChat />
      ) : (
        isNotRecommended === true && (
          <KeywordInputChat
            isNextWithInput={isNextWithInput}
            setDisabled={setDisabled}
          />
        )
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export default KeywordChat;
