import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import {
  DefaultBtn,
  SelectBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import { getRegionDistrict, regionKeys } from "apis/region";

import styled from "styled-components";

const AddressDistrictList = () => {
  const { city = "" } = useParams();
  const navigate = useNavigate();

  const [focusList, setFocusList] = useState<string[]>([]);

  const { data } = useQuery({
    queryKey: regionKeys.getDistrict(city),
    queryFn: async () => await getRegionDistrict(city),
    select: (response) => response.data.result,
  });

  const isFocus = useCallback(
    (district: string) => {
      return focusList.includes(district);
    },
    [focusList]
  );

  const onSelectHandler = (district: string) => {
    if (focusList.includes(district)) {
      setFocusList((prev) => prev.filter((index) => index !== district));
    } else {
      if (focusList.length >= 3) {
        return;
      }
      setFocusList((prev) => [...prev, district]);
    }
  };

  const disabled = useMemo(() => focusList.length < 1, [focusList]);

  const onClickHandler = () => {
    navigate("/address/confirm", {
      state: { district: `${focusList.join(",")}`, city: city },
    });
  };
  if (!data) return;

  return (
    <>
      <Container>
        <p>{"시/군/구를 선택하세요\n다중 선택 가능합니다."}</p>
        <List>
          <ul>
            {city === "세종" ? (
              <li>
                <SelectBtn
                  isFocus={isFocus("전체")}
                  onClick={() => onSelectHandler("전체")}
                >
                  {"전체"}
                </SelectBtn>
              </li>
            ) : (
              [{ city: city, district: "전체" }, ...data]?.map(
                ({ district }, idx) => (
                  <li key={idx}>
                    <SelectBtn
                      isFocus={isFocus(district)}
                      onClick={() => onSelectHandler(district)}
                    >
                      {district}
                    </SelectBtn>
                  </li>
                )
              )
            )}
          </ul>
        </List>
        <ButtonSection>
          <DefaultBtn disabled={disabled} onClick={onClickHandler} />
        </ButtonSection>
      </Container>
    </>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    padding: 2.2rem 1.6rem 1.5rem;
    ${typography.heading2};
    color: var(--color-black);
  }
`;

const List = styled.div`
  flex: 1;
  padding: 1.5rem 1.6rem 10rem;
  overflow-y: auto;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 1.2rem;

    li {
      width: calc(50% - 0.6rem);
    }
  }
`;

const ButtonSection = styled.section`
  width: 100%;
  padding: 0px 1.6rem 4rem;
`;

export default AddressDistrictList;
