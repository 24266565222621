import request, { BaseResponse } from "apis/core";
import { AxiosRequestConfig } from "axios";
import type { CommonUserInfo } from "userInfoTypes";
import type { SignupRequest } from "userSignupTypes";

export const patchCheckNickname = (
  nickname: string,
  config?: AxiosRequestConfig
) => {
  const url = `/app/users/auth/find-by-nickname?nickname=${nickname}`;
  return request.patch<BaseResponse<boolean>>(url, undefined, config);
};

export const patchSignup = (
  data: SignupRequest,
  config?: AxiosRequestConfig
) => {
  const url = "/app/users/auth/sign-up";
  return request.patch<BaseResponse<CommonUserInfo>>(url, data, config);
};

export const userAuthKeys = (userHeadKey: string[]) => {
  return {
    checkNickname: [...userHeadKey, "auth", "checkNickname"] as const,
    signup: [...userHeadKey, "auth", "sign-up"] as const,
  };
};
