import request, { BaseResponse } from "apis/core";
import { AxiosRequestConfig } from "axios";

import type { AddressRequest, UserAddress } from "userAddressTypes";

export const getUserAddress = (config?: AxiosRequestConfig) => {
  const url = "/app/user/address";
  return request.get<BaseResponse<UserAddress[]>>(url, config);
};

export const postUserAddress = (
  body: AddressRequest,
  config?: AxiosRequestConfig
) => {
  const url = `/app/user/address`;
  return request.post<BaseResponse<UserAddress[]>>(url, body, config);
};

export const postRemoveUserAddress = (
  addressId: number,
  config?: AxiosRequestConfig
) => {
  const url = `/app/user/address/${addressId}`;
  return request.post<BaseResponse<UserAddress[]>>(url, "", config);
};

export const userAddressKeys = (userHeadKey: string[]) => {
  return {
    getAddress: [...userHeadKey, "address"] as const,
    postAddress: [...userHeadKey, "address", "post"] as const,
    removeAddress: [...userHeadKey, "address", "postRemove"] as const,
  };
};
