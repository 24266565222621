import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  DefaultBtn,
  typography,
} from "@woojin0808/seniorz-react-ts-web-design-system";

import Home1 from "assets/2.0/home1.png";
import Home2 from "assets/2.0/home2.png";
import styled from "styled-components";
import useAuth from "hooks/useAuth";

const Home = () => {
  const navigate = useNavigate();

  const { isLogined } = useAuth();

  const locationList = localStorage.getItem("location") ?? "";
  const keywordIdList = localStorage.getItem("keyword") ?? "";
  const names = localStorage.getItem("names") ?? "";

  const onClickHandler = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (isLogined) {
      if (!locationList && !keywordIdList && !names) {
        navigate("/chat", { replace: true });
      } else {
        navigate("/my/result", { replace: true });
      }
    }
  }, [isLogined, locationList, keywordIdList, names]);

  return (
    <>
      <Container>
        <div>
          <p>{"시니어즈 일자리 찾기"}</p>
          <img src={Home1} alt="home_first" />
          <img src={Home2} alt="home_first" />
        </div>
      </Container>
      <ButtonSection>
        <DefaultBtn onClick={onClickHandler}>
          {"일자리 바로 추천받기"}
        </DefaultBtn>
      </ButtonSection>
    </>
  );
};

const Container = styled.article`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 10rem;

  div {
    p {
      width: 100%;
      padding: 1.1rem 1.6rem;
      border-bottom: 1px solid var(--color-gray-50);
      ${typography.heading2};
      color: var(--color-gray-900);
      text-align: center;
    }

    img {
      width: 100%;
    }
  }
`;

const ButtonSection = styled.section`
  position: absolute;
  bottom: 0rem;
  width: 100%;
  padding: 0px 1.6rem 4rem;
`;

export default Home;
