import { commonStoreForm, Set } from "utils/store";

import { AddressRequest } from "userAddressTypes";

export type KeywordSelectType = {
  text: string;
  value: number[] | null | "possible";
} | null;

export type KeywordSelectList = {
  location: KeywordSelectType;
  physical: KeywordSelectType;
  preferSit: KeywordSelectType;
  home: KeywordSelectType;
  computer: KeywordSelectType;
  computerDetail: KeywordSelectType;
  write: KeywordSelectType;
  communication: KeywordSelectType;
  time: KeywordSelectType;
  simple: KeywordSelectType;
  rule: KeywordSelectType;
};

export type JobSearchInfoType = {
  locationList: AddressRequest[];
  isNotRecommended: boolean | undefined;
  names: string[]; // When "isNotRecommended" is true
  keywordIdList: KeywordSelectList; // When "isNotRecommended" is false
};

const initInfo: JobSearchInfoType = {
  locationList: [],
  isNotRecommended: undefined,
  names: [],
  keywordIdList: {
    location: null,
    physical: null,
    preferSit: null,
    home: null,
    computer: null,
    computerDetail: null,
    write: null,
    communication: null,
    time: null,
    simple: null,
    rule: null,
  },
};

type Action = {
  setLocationList: (locationList: AddressRequest[]) => void;
  setIsNotRecommended: (isNotRecommended?: boolean) => void;
  setNames: (names: string[]) => void;
  setKeywordIdList: (params: {
    key: keyof KeywordSelectList;
    value: KeywordSelectType;
  }) => void;
  setClearAll: () => void;
  setClearKeyword: () => void;
  setClearNames: () => void;
};

const action = (set: Set<JobSearchInfoType, Action>): Action => ({
  setLocationList: (locationList) => set((prev) => ({ ...prev, locationList })),
  setIsNotRecommended: (isNotRecommended) =>
    set((prev) => ({ ...prev, isNotRecommended })),
  setNames: (names) => set((prev) => ({ ...prev, names })),
  setKeywordIdList: (params) =>
    set((prev) => ({
      ...prev,
      keywordIdList: { ...prev.keywordIdList, [params.key]: params.value },
    })),

  setClearAll: () => set(() => initInfo),
  setClearKeyword: () =>
    set((prev) => ({ ...prev, keywordIdList: initInfo.keywordIdList })),
  setClearNames: () => set((prev) => ({ ...prev, names: initInfo.names })),
});

const useJobSearchInfoStore = commonStoreForm<JobSearchInfoType, Action>({
  initialState: initInfo,
  action,
});

export default useJobSearchInfoStore;
