import {
  DefaultPopup,
  DefaultPopupProps,
  DefaultPopupTypes,
  DimmedScreen,
} from "@woojin0808/seniorz-react-ts-web-design-system";
import React from "react";
import usePopupStore from "stores/popup";
import styled from "styled-components";

const Popup = <T extends DefaultPopupTypes>(props: DefaultPopupProps<T>) => {
  const { isOpen, types, title, content, btnText, onClick } = props;
  const { btnTextSub, onClickSub } = props as DefaultPopupProps<"secondary">;

  const { setIsOpen } = usePopupStore((state) => state.action);

  const onClickBack = () => {
    setIsOpen(false);
  };
  return (
    <Container onClick={onClickBack}>
      <DimmedScreen />
      {types === "default" ? (
        <DefaultPopup
          types="default"
          isOpen={isOpen}
          title={title}
          content={content}
          btnText={btnText}
          onClick={onClick}
        />
      ) : (
        <DefaultPopup
          isOpen={isOpen}
          types={"secondary"}
          title={title}
          content={content}
          onClick={onClick}
          btnText={btnText}
          btnTextSub={btnTextSub}
          onClickSub={onClickSub}
        />
      )}
    </Container>
  );
};

const Container = styled.div``;

export default Popup;
